import axios from 'axios';
import { ArticleStore, CigarStore, ShopStore, VenueStore } from '../../stores';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

export const saveItem = (user, type, item, collection) => {
  if (user && user.id) {
    // TODO Trigger "Add to collection" prompt if collection is null
    axios.post(`${config.apiEndPoint}/users/${user.id}/saveditems`, {
      item_id: item.id,
      type,
      collection_type: collection,
    }).then((response) => {
      const savedItem = response.data;
      console.log(savedItem);
      // TODO Update Pullstate with remaining items and look into simplifying this logic and the logic below
      if (type === 'cigar') {
        if (collection === 'try_list') {
          CigarStore.update((s) => {
            s.saved.unshift(parseInt(savedItem.item_id));
          });
        } else if (collection === 'favorites') {
          CigarStore.update((s) => {
            s.favorites.unshift(parseInt(savedItem.item_id));
          });
        }
      } else if (item.type === 'venue') {
        VenueStore.update((s) => {
          s.saved.unshift(parseInt(savedItem.item_id));
        });
      } else if (item.type === 'product') {
        ShopStore.update((s) => {
          s.products.saved.unshift(parseInt(savedItem.item_id));
        });
      } else if (item.type === 'article') {
        ArticleStore.update((s) => {
          s.saved.unshift(parseInt(savedItem.item_id));
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  } else {
    // TODO Redirect to login
  }
};

export const unsaveItem = (user, item) => {
  console.log('Removing item:');
  console.log(item);
  axios.delete(`${config.apiEndPoint}/users/${user.id}/saveditems`, { data: item })
    .then((response) => {
      const savedItem = response.data;
      console.log(savedItem);
      // TODO Update Pullstate to remove the item from the store
      if (item.type === 'cigar') {
        if (item.collection_type === 'try_list') {
          CigarStore.update((s) => {
            s.saved.splice(s.saved.indexOf(parseInt(item.item_id)), 1);
          });
        } else if (item.collection_type === 'favorites') {
          CigarStore.update((s) => {
            s.favorites.splice(s.favorites.indexOf(parseInt(item.item_id)), 1);
          });
        }
      } else if (item.type === 'venue') {
        VenueStore.update((s) => {
          s.saved.splice(s.saved.indexOf(parseInt(item.item_id)), 1);
        });
      } else if (item.type === 'product') {
        ShopStore.update((s) => {
          s.products.saved.splice(s.saved.indexOf(parseInt(item.item_id)), 1);
        });
      } else if (item.type === 'article') {
        ArticleStore.update((s) => {
          s.saved.splice(s.saved.indexOf(parseInt(item.item_id)), 1);
        });
      }
    }).catch((err) => {
      console.log(err);
    });
};
