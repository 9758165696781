import { put, takeLatest } from 'redux-saga/effects';
import { loadSavedItems, LOAD_SAVED_ITEMS, SAVE_ITEM, UNSAVE_ITEM } from './actions';
// import axios from 'axios';
import firebase from 'firebase';

export function* loadItems(action) {
  try {
    const userId = JSON.parse(window.localStorage.getItem('user')).id;
    const itemId = action.item.id;

    const item = JSON.parse(JSON.stringify(action.item));
    const type = action.itemType;
    item.type = type;

    console.log(`Saving item ${itemId} for user ${userId}`);

    // yield axios.post(`${config.apiEndPoint}/users/${userId}/savedItems`, updatedItem);
    yield put(loadSavedItems());
  } catch (err) {
    console.log(err);
  }
}

export function* loadItemsWatcher() {
  yield takeLatest(LOAD_SAVED_ITEMS.REQUEST, loadItems);
}

export function* saveItem(action) {
  try {
    // const userUid = firebaseApp.auth().currentUser.uid;
    const userId = JSON.parse(window.localStorage.getItem('user')).id;
    const itemId = action.item.id;

    const item = JSON.parse(JSON.stringify(action.item));
    const type = action.itemType;
    item.type = type;

    // FIXME I think adding to a collection should have it's own function that updates the default collection field (so we know where to delete it from)
    //       This way, it adds it to both locations, which just makes querying a lot easier
    // const collection = action.collection;
    // item.collection = action.collection; // Only gets the collection linked to the object if available

    console.log(`Saving item ${itemId} for user ${userId}`);

    const userRef = firebase.firestore().collection('users').doc(userId);
    yield userRef.collection('saved').doc('active').set({
      items: firebase.firestore.FieldValue.arrayUnion(item),
    }, { merge: true });

    // yield axios.post(`${config.apiEndPoint}/users/${userId}/savedItems`, updatedItem);
    yield put(loadSavedItems());
  } catch (err) {
    console.log(err);
  }
}

export function* saveItemWatcher() {
  yield takeLatest(SAVE_ITEM, saveItem);
}

/**
 * @deprecated
 * @param action
 * @returns {Generator<{page: *, type: *, userId: *}|Promise<void>, void, *>}
 */
export function* unsaveItem(action) {
  try {
    // const userUid = firebaseApp.auth().currentUser.uid;
    const userId = JSON.parse(window.localStorage.getItem('user')).id;
    const item = JSON.parse(JSON.stringify(action.item));
    const collection = action.item.collection;
    const userRef = firebase.firestore().collection('users').doc(userId);

    yield userRef.collection('saved').doc('inactive').set({
      items: firebase.firestore.FieldValue.arrayUnion(item),
    }, { merge: true });

    yield userRef.collection('saved').doc('active').set({
      items: firebase.firestore.FieldValue.arrayRemove(item),
    }, { merge: true });

    if (collection) {
      yield userRef.collection('saved').doc(collection).set({
        items: firebase.firestore.FieldValue.arrayRemove(item),
      }, { merge: true });
    }

    // yield axios.delete(`${config.apiEndPoint}/users/${userId}/savedItems`, action.item);
    yield loadSavedItems();
  } catch (err) {
    console.log(err);
  }
}

export function* unsaveItemWatcher() {
  yield takeLatest(UNSAVE_ITEM, unsaveItem);
}

export default [
  loadItemsWatcher,
  saveItemWatcher,
  unsaveItemWatcher,
];
