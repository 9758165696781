import { createAsyncAction } from '../../utils/actionUtils';

export const LOAD_SAVED_ITEMS = createAsyncAction('LOAD_SAVED_ITEMS');
export const SAVE_ITEM = 'SAVE_ITEM';
export const UNSAVE_ITEM = 'UNSAVE_ITEM';

export function requestSavedPosts(userId, page = 1, options = {}) {
  return {
    type: LOAD_SAVED_ITEMS.REQUEST,
    userId,
    page,
    options,
  };
}

export function loadSavedItems(userId, page = 1) {
  return {
    type: LOAD_SAVED_ITEMS.FETCH,
    userId,
    page,
  };
}

export function loadSavedItemsSuccess(userId, paging, posts) {
  return {
    type: LOAD_SAVED_ITEMS.SUCCESS,
    userId,
    paging,
    posts,
  };
}

export function loadSavedItemsFail(userId, error) {
  return {
    type: LOAD_SAVED_ITEMS.FAILURE,
    userId,
    error,
  };
}

export function saveItem(type, item, collection) {
  console.log('Triggered saveItem');
  return {
    type: SAVE_ITEM,
    itemType: type,
    item,
    collection,
  };
}

export function unsaveItem(type, item) {
  return {
    type: UNSAVE_ITEM,
    itemType: type,
    item,
  };
}
