import { fromJS, List } from 'immutable';
import {
  LOAD_HUMIDORS,
  ADD_OR_UPDATE_HUMIDOR,
  ADD_OR_UPDATE_HUMIDOR_ENTRY,
  REMOVE_HUMIDOR_ENTRY,
  mergeHumidorEntry,
} from './actions';
import { Transducer } from '../../utils/transducer';

const humidorState = fromJS({
  loading: true,
  errorMessage: '',
  humidors: List(),
});

// FIXME Phase this file out - we're no longer using Redux for state management
export function humidorReducer(state = humidorState, action) {
  switch (action.type) {
    case LOAD_HUMIDORS.FETCH: {
      return state.set('loading', true);
    }

    case LOAD_HUMIDORS.SUCCESS: {
      const { humidors, callback } = action;
      if (typeof callback === 'function') {
        console.log('Running callback for success');
        console.log(humidors);
        if (humidors.length === 0) {
          Transducer.setPreference('show_create_humidor_card', 'true');
        } else {
          Transducer.setPreference('show_create_humidor_card', 'false');
        }
        callback(humidors);
      }
      return state
        .set('loading', false)
        .set('humidors', fromJS(humidors));
    }

    case LOAD_HUMIDORS.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_HUMIDORS.CANCEL: {
      return state.set('loading', false);
    }

    case ADD_OR_UPDATE_HUMIDOR: {
      const humidors = state.get('humidors').toJS();
      // TODO Push the humidor in the list or replace
      return state.set('humidors', fromJS(humidors));
    }

    case ADD_OR_UPDATE_HUMIDOR_ENTRY.FETCH: {
      return state.set('loading', true);
    }

    case ADD_OR_UPDATE_HUMIDOR_ENTRY.SUCCESS: {
      // console.log('Running entry success reducer:');
      // console.log(action);
      const { entry, callback } = action;
      const humidors = state.get('humidors').toJS();
      if (typeof callback === 'function') {
        console.log('Running callback for entry success');
        console.log(entry);
        callback(entry);
      }
      return state.set('humidors', fromJS(humidors));
    }

    case ADD_OR_UPDATE_HUMIDOR_ENTRY.FAILURE: {
      console.log('Running entry failure reducer:');
      const { callback } = action;
      if (typeof callback === 'function') {
        callback(null, action.error);
      }
      return state
        .set('loading', false)
        .set('error', action.error);
    }

    // FIXME This is deprecated - it gets handled in actions.js, however, the server code is still executed in the saga.
    case REMOVE_HUMIDOR_ENTRY: {
      const { humidorId, entry } = action;
      // entry.qty = 0;
      // entry.humidor_id = humidorId;
      // const humidors = state.get('humidors').toJS();
      // console.log('Existing humidors:');
      // console.log(humidors);
      // for (let i = 0; i < humidors.length; i++) {
      //   const humidor = humidors[i];
      //   if (humidor.id === humidorId) {
      //     console.log('Found the correct humidor for the entry!');
      //     console.log(humidor);
      //     for (let j = 0; j < humidor.contents.length; j++) {
      //       if (humidor.contents[j].id === entry.id) {
      //         console.log('Found the entry! Replacing it now...');
      //         // Remove and finish
      //         humidor.contents.splice(j, 1);
      //         return state.set('humidors', fromJS(humidors));
      //       }
      //     }
      //     // Updates the cached humidor
      //     Transducer.setPreference('activeHumidor', humidor); // FIXME This should be in the Redux store, not handled like this
      //     // console.log('Could not find the entry to remove..');
      //   }
      // }
      // mergeHumidorEntry(entry);
      return state.set('humidors', fromJS(state.get('humidors').toJS()));
    }

    default:
      return state;
  }
}

export const initialState = fromJS({});
export default function humidorMappingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HUMIDORS.FETCH:
    case LOAD_HUMIDORS.SUCCESS:
    case LOAD_HUMIDORS.FAILURE:
    case ADD_OR_UPDATE_HUMIDOR:
    case ADD_OR_UPDATE_HUMIDOR_ENTRY.FETCH:
    case ADD_OR_UPDATE_HUMIDOR_ENTRY.SUCCESS:
    case ADD_OR_UPDATE_HUMIDOR_ENTRY.FAILURE:
    case REMOVE_HUMIDOR_ENTRY: {
      const selectedList = state.get(action.userId);
      const updatedList = humidorReducer(selectedList, action);
      return state.set(action.userId, updatedList);
    }
    default:
      return state;
  }
}
