import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  loadHumidors,
  loadHumidorsFail,
  loadHumidorsSuccess,
  LOAD_HUMIDORS,
  ADD_OR_UPDATE_HUMIDOR_ENTRY, addUpdateHumidorEntrySuccess, addUpdateHumidorEntryFail,
} from './actions';
import { config } from '../../settings';

export function* loadHumidorsRequest(action) {
  const { userId, callback } = action;
  try {
    console.log(`Getting humidors for ${userId}...`);
    yield put(loadHumidors(userId));
    const response = yield axios.get(`${config.apiEndPoint}/users/${userId}/humidors`);
    // console.log('Got humidors');
    // console.log(response.data);
    yield put(loadHumidorsSuccess(userId, response.data, callback));
  } catch (e) {
    console.log(e);
    yield put(loadHumidorsFail(userId, e));
  }
}

export function* loadHumidorsWatcher() {
  yield takeLatest(LOAD_HUMIDORS.REQUEST, loadHumidorsRequest);
}

export function* addUpdateHumidorEntry(action) {
  const { entry, humidorId, callback } = action;
  console.log('Uploading entry...');
  console.log(entry);
  try {
    if (entry.id) {
      console.log('Updating entry...');
      const response = yield axios.put(`${config.apiEndPoint}/humidors/${humidorId}/entries/${entry.id}`, entry);
      console.log(response);
      yield put(addUpdateHumidorEntrySuccess(response.data, callback));
    } else {
      console.log('Creating new entry...');
      const response = yield axios.post(`${config.apiEndPoint}/humidors/${humidorId}/entries?return=entry`, entry);
      console.log(response);
      yield put(addUpdateHumidorEntrySuccess(response.data, callback));
    }
  } catch (err) {
    console.log(err);
    yield put(addUpdateHumidorEntryFail(entry, err));
  }
}

// FIXME If we add the code to remove from humidor here (prob not necessary, but maybe...) we need to handle moving entry
//  between humidors in HumidorEntryCard -- it's set to call the reducer to remove it from the humidor in state, but we
//  don't actually want to set the qty to 0 in the database

export function* loadAddUpdateHumidorEntryWatcher() {
  yield takeLatest(ADD_OR_UPDATE_HUMIDOR_ENTRY.REQUEST, addUpdateHumidorEntry);
}

export default [
  loadHumidorsWatcher,
  loadAddUpdateHumidorEntryWatcher,
];
