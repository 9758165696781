import { fromJS } from 'immutable';
import { DELETE_PRODUCT_REVIEW } from './actions';

const deleteReviewState = fromJS({
  loading: false,
  errorMessage: '',
});
export function deleteReviewReducer(state = deleteReviewState, action) {
  switch (action.type) {
    case DELETE_PRODUCT_REVIEW.FETCH: {
      return state.set('loading', true);
    }

    case DELETE_PRODUCT_REVIEW.SUCCESS: {
      // TODO Kick off updating feed, product reviews, and user reviews
      return state.set('loading', false);
    }

    case DELETE_PRODUCT_REVIEW.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case DELETE_PRODUCT_REVIEW.CANCEL: {
      return state.set('loading', false);
    }

    default:
      return state;
  }
}

const initalState = fromJS({});
export default function listMappingReducer(state = initalState, action) {
  switch (action.type) {
    case DELETE_PRODUCT_REVIEW.FETCH:
    case DELETE_PRODUCT_REVIEW.SUCCESS:
    case DELETE_PRODUCT_REVIEW.FAILURE: {
      const selectedList = state.get(action.review.id);
      const updatedList = deleteReviewReducer(selectedList, action);
      return state.set(action.review.id, updatedList);
    }
    default:
      return state;
  }
}
