import { createAsyncAction } from '../../utils/actionUtils';
import { ShopStore } from '../../stores';
import array from 'lodash/array';

export const LOAD_PRODUCT_REVIEWS = createAsyncAction('LOAD_PRODUCT_REVIEWS');
export const LOAD_USER_REVIEWS = createAsyncAction('LOAD_USER_REVIEWS');
export const SAVE_PRODUCT_REVIEWS = createAsyncAction('SAVE_PRODUCT_REVIEWS');
export const DELETE_PRODUCT_REVIEW = createAsyncAction('DELETE_PRODUCT_REVIEW');

export const addOrMergeReviews = (review, state) => {
  const productId = review.product_id;
  const reviews = state.products.reviews[productId] || [];
  // FIXME Better way to do this?
  for (let i = 0; i < reviews.length; i++) {
    if (parseInt(reviews[i].id) === parseInt(review.id)) {
      console.log('Found! Updating!');
      // eslint-disable-next-line no-param-reassign
      reviews[i] = review;
      console.log(reviews);
      return;
    }
  }
  console.log('Adding to beginning');
  reviews.unshift(review);
};

export const mergeReviews = (reviews, state) => {
  if (reviews.length > 0) {
    const productId = reviews[0].product_id;
    const existingReviews = state.products.reviews[productId] || [];
    array.unionBy(existingReviews, reviews, 'id');
  }
};

export const removeReview = (review, state) => {
  console.log('removeReview');
  console.log(review);
  const productId = review.product_id;
  const reviews = state.products.reviews[productId] || [];
  for (let i = 0; i < reviews.length; i++) {
    if (parseInt(reviews[i].id) === parseInt(review.id)) {
      console.log('Found! Removing!');
      reviews.splice(i, 1);
      return;
    }
  }
};

export function requestProductReviews(productId, page = 1, callback) {
  ShopStore.update((s) => { s.products.reviews.loading = true; });
  // TODO We can use runSaga() here - https://redux-saga.js.org/docs/api/index.html#external-api
  return {
    type: LOAD_PRODUCT_REVIEWS.REQUEST,
    productId,
    page,
    callback,
  };
}

export function loadProductReviews(productId, page = 1) {
  // TODO Axios call
  return {
    type: LOAD_PRODUCT_REVIEWS.FETCH,
    productId,
    page,
  };
}

export function loadProductReviewsSuccess(productId, paging, reviews, callback) {
  console.log('Got reviews for product: ', productId);
  console.log(reviews);
  ShopStore.update((s) => {
    s.products.reviews.loading = false;
    s.products.reviews[productId] = reviews;
  });
  // return {
  //   type: LOAD_PRODUCT_REVIEWS.SUCCESS,
  //   productId,
  //   paging,
  //   reviews,
  //   callback,
  // };
}

export function loadProductReviewsFail(productId, error) {
  ShopStore.update((s) => { s.products.reviews.loading = true; });
  return {
    type: LOAD_PRODUCT_REVIEWS.FAILURE,
    productId,
    error,
  };
}

export function saveReview(review) {
  return {
    type: SAVE_PRODUCT_REVIEWS.REQUEST,
    review,
  };
}

export function savingUserReview(review) {
  // TODO Axios call or skip and merge into saveReview above
  return {
    type: SAVE_PRODUCT_REVIEWS.FETCH,
    review,
  };
}

export function saveReviewSuccess(review) {
  console.log('Saved! Updating reviews...');
  // TODO Do feed, too? Or simply ensure it gets pulled again?
  ShopStore.update((s) => {
    addOrMergeReviews(review, s);
  });
  return {
    type: SAVE_PRODUCT_REVIEWS.SUCCESS,
    review,
  };
}

export function saveReviewFail(review, error) {
  return {
    type: SAVE_PRODUCT_REVIEWS.FAILURE,
    review,
    error,
  };
}

export function deleteProductReview(review) {
  console.log('Requesting delete product review...');
  return {
    type: DELETE_PRODUCT_REVIEW.REQUEST,
    review,
  };
}

export function deletingProductReview(review) {
  return {
    type: DELETE_PRODUCT_REVIEW.FETCH,
    review,
  };
}

export function deleteProductReviewSuccess(review) {
  ShopStore.update((s) => {
    removeReview(review, s);
  });
  return {
    type: DELETE_PRODUCT_REVIEW.SUCCESS,
    review,
  };
}

export function deleteProductReviewFail(review, error) {
  return {
    type: DELETE_PRODUCT_REVIEW.FAILURE,
    review,
    error,
  };
}
