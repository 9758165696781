import React from 'react';
import './style.scss';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { isAndroid } from 'mobile-device-detect';
import IconButton from '@material-ui/core/IconButton';
import safeAreaInsets from 'safe-area-insets';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Icon from '../../components/Icon';

// FIXME I'd really like to see their humidor, favorites, and Try Lists cached for offline use
//  so those routes should be whitelisted from this - they should be able to view them even with not internet
class NetworkErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { offline: !window.navigator.onLine };
    window.addEventListener('online', () => {
      this.setState({ offline: false });
    });
    window.addEventListener('offline', () => {
      this.setState({ offline: true });
    });
  }

  goBack = () => {
    window.history.back();
  }

  backArrow = () => {
    if (isAndroid) {
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Go Back"
          onClick={this.props.onClose || this.goBack}
        >
          <Icon
            name="arrow-left"
            size={22}
            style={{ height: 22, width: 22 }}
          />
        </IconButton>
      );
    }
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Go Back"
        onClick={this.props.onClose || this.goBack}
      >
        <Icon
          name="chevron-left"
          size={20}
          style={{ height: 22, width: 22 }}
        />
        {/* <span style={{ fontSize: 14 }}>Back</span> */}
      </IconButton>
    );
  };

  render() {
    const { offline } = this.state;
    const styles = {};
    const insetTop = safeAreaInsets.top;
    if (window.Capacitor.platform === 'ios') {
      styles.height = `${56 + insetTop}px`;
      styles.paddingTop = insetTop;
    }
    if (offline) {
      return (
        <div style={{ textAlign: 'center', margin: 16 }}>
          <AppBar
            // position="static"
            color="white"
            style={styles}
          >
            <Toolbar>
              { this.backArrow() }
            </Toolbar>
          </AppBar>
          <h1 style={{ marginBottom: 10, paddingTop: 70 }}>Unable to connect</h1>
          <p>
            {'You appear to be offline. Please check your Internet connection.'}
          </p>
          <br />
          <div>
            <p>Would you like to refresh the app?</p>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={() => window.location.reload()}
            >
              {'Refresh'}
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(NetworkErrorBoundary);
