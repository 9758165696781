import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  DELETE_PRODUCT_REVIEW,
  deletingProductReview,
  deleteProductReviewSuccess,
  deleteProductReviewFail,
} from './actions';
import { config } from '../../settings';

function* deleteReview(action) {
  const { review } = action;
  console.log('Deleting checkin...');
  yield put(deletingProductReview(review));
  try {
    const response = yield axios.delete(`${config.shopEndPoint}/products/reviews/${review.id}`);
    console.log(response);
    yield put(deleteProductReviewSuccess(review));
  } catch (err) {
    console.log(err);
    yield put(deleteProductReviewFail(review, err));
  }
}

export function* deleteReviewWatcher() {
  yield takeLatest(DELETE_PRODUCT_REVIEW.REQUEST, deleteReview);
}

export default [
  deleteReviewWatcher,
];
