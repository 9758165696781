import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const selectHumidorMapping = () => (state) => {
  console.log('selectHumidorMapping');
  console.log(state.getIn(['global', 'humidors']));
  console.log(state.getIn(['global', 'humidors']).toJS());
  return state.getIn(['global', 'humidors']);
};
export const selectHumidorState = (userId) => createSelector(
  selectHumidorMapping(),
  // FIXME Not sure why this maps out fine with toJS(), but the .get() returns undefined
  //  https://github.com/immutable-js/immutable-js/issues/1767
  (humidorState) => {
    const state = humidorState.toJS();
    const userState = state[userId];
    if (userState) {
      return fromJS(userState);
    }
    return fromJS({
      loading: false,
      errorMessage: '',
      humidors: List(),
    });
  }
);

export const selectHumidorLoading = (userId) => createSelector(
  selectHumidorState(userId),
  (humidorState) => humidorState.get('loading'),
);

export const selectHumidorErrorMessage = (userId) => createSelector(
  selectHumidorState(userId),
  (humidorState) => humidorState.get('errorMessage'),
);

export const selectAllHumidors = (userId) => createSelector(
  selectHumidorState(userId),
  (humidorState) => {
    console.log(`Humidor state for user ${userId}`);
    console.log(humidorState.toJS());
    return humidorState.get('humidors').toJS();
  },
);

export const selectHumidorContents = (userId, humidorId) => createSelector(
  selectAllHumidors(userId),
  (humidorState) => {
    let contents = [];
    const humidors = humidorState.get('humidors').toJS();
    humidors.forEach((humidor) => {
      if (humidor.id === humidorId) {
        // console.log(humidor.contents);
        contents = humidor.contents;
      }
    });
    return contents;
  },
);
